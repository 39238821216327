exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-dighist-course-tsx": () => import("./../../../src/templates/dighist-course.tsx" /* webpackChunkName: "component---src-templates-dighist-course-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-scenarios-tsx": () => import("./../../../src/templates/scenarios.tsx" /* webpackChunkName: "component---src-templates-scenarios-tsx" */),
  "component---src-templates-timeline-tsx": () => import("./../../../src/templates/timeline.tsx" /* webpackChunkName: "component---src-templates-timeline-tsx" */),
  "component---src-templates-timelines-tsx": () => import("./../../../src/templates/timelines.tsx" /* webpackChunkName: "component---src-templates-timelines-tsx" */)
}

