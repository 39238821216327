import React from "react"
import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
// use gatsby browser to wrap the root element in a provider of redux
import { Provider } from "react-redux"
import { store } from "./src/store"
import { Helmet } from "react-helmet"

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <Helmet>
        <title>Dighist</title>
        <meta name="title" content="Dighist" />
        <meta
            name="description"
            content="Digital Historytelling introduces innovative tools for teaching 20th-century European history and civic education."
        />
        <meta
            name="image"
            content="https://www.admin.dighist.eu/wp-content/uploads/2023/12/dighist-image.png"
        />
        <meta property="og:title" content="Dighist" />
        <meta
            property="og:description"
            content="Digital Historytelling introduces innovative tools for teaching 20th-century European history and civic education."
        />
        <meta
            property="og:image"
            content="https://www.admin.dighist.eu/wp-content/uploads/2023/12/dighist-image.png"
        />
        <meta property="og:url" content="https://dighist.eu/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Dighist" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700;800&family=Poppins:wght@200;300;400;700&display=swap"
            rel="stylesheet"
        />
        <link
            title="timeline-styles"
            rel="stylesheet"
            href="https://cdn.knightlab.com/libs/timeline3/latest/css/timeline.css"
        />
        <script src="https://cdn.knightlab.com/libs/timeline3/latest/js/timeline.js"></script>
        {/* Add more meta tags, Open Graph tags, etc. as needed */}
      </Helmet>
      {element}
    </Provider>
  )
}
